import type React from "react";
import { useEffect } from "react";
import classnames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import ArrowDown from "@/assets/svg/menu/arrow-down.svg?react";
import { useAccordionList } from "@/base/components/AccordionList/AccordionListProvider";
import type { AccordionItemType } from "@/base/components/AccordionList/types";
import { Badge, Icon, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

const TRANSFORM_DURATION = ".2s";

const useStyles = createUseStyles(({ color, spacing, opacity }) => ({
  accordionItem: {
    borderBottom: `1px solid ${color.gray5}`,
    cursor: "pointer",
    color: color.black, // for SVG icons
  },
  noBorder: {
    borderBottom: "none",
  },
  suffixIcon: {
    marginLeft: spacing.m,
  },
  arrowOpen: {
    transform: "rotate(180deg)",
    transition: `all ${TRANSFORM_DURATION} ease`,
  },
  arrowClose: {
    transform: "rotate(0deg)",
    transition: `all ${TRANSFORM_DURATION} ease`,
  },
  colIcon: {
    maxWidth: 40,
  },
  disabled: {
    opacity: opacity.m,
    cursor: "default",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
  },
}));

const AccordionItem: React.FC<AccordionItemType> = (accordionItem) => {
  const {
    id,
    title,
    iconURL,
    icon,
    fill,
    num,
    suffix,
    children,
    arrowIcon,
    containerClassname,
    titleClassname,
    slim,
    noBorder,
    onClose,
    isActiveByDefault,
    isDisabled = false,
  } = accordionItem;
  const [{ activeItem }, { setActiveItem }] = useAccordionList();
  const isActiveItem = Boolean(activeItem && activeItem.id === id);
  const classes = useStyles();

  const toggleItem = () => {
    if (isDisabled) return;
    if (isActiveItem) {
      setActiveItem(null);
      if (onClose) onClose();
    } else {
      setActiveItem(accordionItem);
    }
  };

  useEffect(() => {
    if (!isDisabled && isActiveByDefault) {
      toggleItem();
    }
  }, []);

  return (
    <Row
      id={id}
      noMargin
      className={classnames([
        classes.accordionItem,
        noBorder && classes.noBorder,
        isDisabled && classes.disabled,
      ])}
      key={id}
    >
      <Col col={12} noHorizontalMargin={slim} onClick={toggleItem}>
        <Row noMargin className={containerClassname}>
          {iconURL && (
            <Col className={classes.colIcon}>
              <Icon icon={iconURL} fill={fill} />
            </Col>
          )}
          {icon && <Col className={classes.colIcon}>{icon}</Col>}
          <Col>
            {title && (
              <P noMargin black className={titleClassname}>
                {title}
              </P>
            )}
            <Badge>{num}</Badge>
          </Col>
          <Col col={2} alignEnd noMarginRight={slim}>
            {suffix && (
              <P black noMargin>
                {suffix}
              </P>
            )}
            {children && (
              <Icon
                icon={arrowIcon ?? <ArrowDown />}
                className={classnames(
                  isActiveItem ? classes.arrowOpen : classes.arrowClose,
                  suffix && classes.suffixIcon,
                )}
              />
            )}
          </Col>
        </Row>
      </Col>
      <AnimatePresence>
        {isActiveItem && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.2 }}
            className={classes.list}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </Row>
  );
};

export default AccordionItem;
