import type React from "react";
import { useTranslation } from "react-i18next";

import { Link, P } from "@/base/components/Global";
import { Col, Row } from "@/base/components/Layout";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    paddingTop: spacing.xxl,
    paddingBottom: spacing.xxl,
  },
}));

const InfoBlock: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Row className={classes.container}>
      <Col col={12} alignCenter>
        <Link brandColor bold mailTo={t("footer_email")}>
          {t("footer_email")}
        </Link>
      </Col>
      <Col col={12} alignCenter>
        <P gray3 bold>
          {t("footer_name")}
        </P>
      </Col>
      <Col col={12} alignCenter>
        <P white bold>
          {t("footer_copyright", { year: new Date().getFullYear() })}
        </P>
      </Col>
    </Row>
  );
};

export default InfoBlock;
