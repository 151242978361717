import type React from "react";
import Dialog from "@mui/material/Dialog";

import type { GenericModalProps } from "@/base/components/Modal/GenericModal";
import MyCard from "@/pages/HomePage/components/MyApolloClubCard/MyCard";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

const ApolloClubCardModal: React.FC<GenericModalProps> = ({
  isOpen = false,
  onClose,
  onNavigate,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        style: { backgroundColor: "transparent", margin: "8px" },
      }}
    >
      <div
        className={classes.container}
        onClick={(e) => {
          e.stopPropagation();
          onClose?.();
        }}
      >
        <div
          className={classes.cardContainer}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MyCard onNavigate={onNavigate} />
        </div>
      </div>
    </Dialog>
  );
};

export default ApolloClubCardModal;
