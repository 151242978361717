import type React from "react";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import BurgerMenu from "@/base/components/BurgerMenu";
import TabBar from "@/base/components/TabBar";
import TopBar from "@/base/components/TopBar";
import config from "@/constants/config";
import type { INavigationPath } from "@/constants/navigation";
import { selectMenuItems } from "@/store/cms";
import { useAppSelector } from "@/store/hooks";
import { createUseStyles } from "@/theme";

import Footer from "../Footer";
import { PAGE_MARGIN_BOTTOM } from "../Layout/constants";
import VisibilityBlock from "../Layout/VisibilityBlock";
import MobileTransparentTopBar from "../TopBar/MobileTopBar/MobileTransparentTopBar";

const useStyles = createUseStyles({
  appContainer: {
    overflowY: "auto",
  },

  container: {
    paddingBottom: PAGE_MARGIN_BOTTOM,
    paddingLeft: 0,
    paddingRight: 0,

    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
});

const GenericRouteLayout: React.FC<{
  children: React.ReactNode;
  showTabBar?: boolean;
  showFooter?: boolean;
  showTopNavbar?: boolean;
  showNavigationToHome?: boolean;
  backPage?: INavigationPath;
}> = ({
  children,
  showTabBar = true,
  showFooter = true,
  showTopNavbar = true,
  showNavigationToHome,
  backPage,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  const classes = useStyles();
  const menuItems = useAppSelector(selectMenuItems);

  return (
    <>
      {showTopNavbar && (
        <TopBar isMenuOpen={isMenuOpen} openMenu={toggleMenu} />
      )}

      <div id="app-container" className={classes.appContainer}>
        {showTopNavbar && config.routerConfig.routerMode !== "giftCard" && (
          <VisibilityBlock belowSm>
            <MobileTransparentTopBar
              backPage={backPage}
              showNavigationToHome={showNavigationToHome}
            />
          </VisibilityBlock>
        )}

        <div className={classes.container}>{children}</div>

        {showFooter && <Footer />}
      </div>

      <VisibilityBlock belowSm>
        <AnimatePresence initial={false}>
          {showTabBar && !isMenuOpen && (
            <TabBar openMenu={() => setIsMenuOpen(!isMenuOpen)} />
          )}
        </AnimatePresence>
      </VisibilityBlock>

      <BurgerMenu
        isMenuOpen={isMenuOpen}
        closeMenu={closeMenu}
        openMenu={toggleMenu}
        menuItems={menuItems}
      />
    </>
  );
};

export default GenericRouteLayout;
