import type React from "react";
import Dialog from "@mui/material/Dialog";

import { Row } from "@/base/components/Layout";
import type { AnalyticsAttributes } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: spacing.l,
  },
}));

type GenericModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
  onNavigate?: () => void;
  children?: React.ReactNode;
  analyticsAttributes?: AnalyticsAttributes;
};

const GenericModal: React.FC<GenericModalProps> = ({
  isOpen = false,
  onClose,
  children,
  analyticsAttributes,
}) => {
  const classes = useStyles();

  if (!children) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Row
        className={classes.container}
        analyticsAttributes={analyticsAttributes}
      >
        {children}
      </Row>
    </Dialog>
  );
};

export type { GenericModalProps };
export default GenericModal;
