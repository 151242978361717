const getAppContainer = () => {
  const appContainer = document.querySelector("#app-container");

  if (!appContainer) {
    throw new Error("App container not found");
  }

  return appContainer;
};

export const scrollToWithOffset = (targetId: string) => {
  const appContainer = getAppContainer();

  const element = document.querySelector(`#${targetId}`);
  const elementPosition = element?.getBoundingClientRect().top || 0;
  const offsetPosition = elementPosition + appContainer.scrollHeight;
  appContainer.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const handleScrollToTop = (smooth = true) => {
  const appContainer = getAppContainer();

  if (appContainer.scrollTop !== 0) {
    appContainer.scrollTo({
      top: 0,
      behavior: smooth ? "smooth" : "auto",
    });
  }
};
