import type React from "react";
import type { MouseEvent } from "react";
import classnames from "classnames";

import {
  TextComponent,
  type TextStyleProps,
  TextWithIcon,
} from "@/base/components/Global";
import type { AnalyticsAttributes } from "@/constants/googleAnalytics";
import { createUseStyles } from "@/theme";

import LoaderTiny from "./LoaderTiny";

const useStyles = createUseStyles(({ color, borderRadius, spacing, font }) => ({
  btn: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    background: color.primaryBrand,
    color: color.white,
    borderRadius: borderRadius.s,
    border: "none",
    cursor: "pointer",
    paddingLeft: spacing.l,
    paddingRight: spacing.l,
    fontSize: font.size.s,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.m,
    height: 48,
  },
  small: {
    width: "auto !important",
  },
  border: {
    borderStyle: "solid",
    borderWidth: 1,
  },
  brandBorder: {
    borderColor: color.primaryBrand,
    backgroundColor: color.white,
    color: color.primaryBrand,
  },
  blackBorder: {
    borderColor: color.black,
    backgroundColor: color.white,
    color: color.black,
  },
  disabled: {
    opacity: "60%",
    cursor: "not-allowed",
  },
  btnText: {
    whiteSpace: "nowrap !important",
    textOverflow: "ellipsis",
    overflow: "hidden",
    gap: spacing.xs,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export type ButtonProps = TextStyleProps & {
  action?: () => void;
  disableAction?: () => void;
  children?: React.ReactNode;
  type?: "submit" | "reset" | "button";
  isDisabled?: boolean;
  transparentBrand?: boolean;
  transparentBlack?: boolean;
  className?: string;
  id?: string;
  href?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  small?: boolean;
  isActive?: boolean;
  isLoading?: boolean;
  "data-testid"?: string;
  analyticsAttributes?: AnalyticsAttributes;
};

const Button: React.FC<ButtonProps> = ({
  action,
  disableAction,
  children,
  type = "button",
  isDisabled = false,
  transparentBrand = false,
  transparentBlack = false,
  className,
  id = "btn",
  href,
  prefixIcon,
  suffixIcon,
  small,
  "data-testid": dataTestId,
  isActive = false,
  isLoading = false,
  analyticsAttributes,
  ...props
}) => {
  const classes = useStyles();

  const onClick = (e: MouseEvent) => {
    if (isLoading) {
      e.preventDefault();
      return;
    }
    if (isDisabled && disableAction) {
      disableAction();
    } else if (!isDisabled) {
      if (href) window.open(href, "_blank");
      if (action) action();
    }
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={classnames([
        classes.btn,
        (transparentBrand || transparentBlack) && classes.border,
        transparentBrand && classes.brandBorder,
        transparentBlack && classes.blackBorder,
        isDisabled && classes.disabled,
        small && classes.small,
        className,
      ])}
      id={`${id}${isDisabled ? "-disabled" : ""}`}
      disabled={isDisabled}
      {...{
        "data-testid": dataTestId,
      }}
      {...analyticsAttributes}
    >
      <TextWithIcon
        prefixIcon={
          isLoading ? (
            <LoaderTiny isLight={!transparentBrand && !transparentBlack} />
          ) : (
            prefixIcon
          )
        }
        suffixIcon={suffixIcon}
        isActive={isActive}
      >
        <TextComponent {...props} classNames={classes.btnText} isButton>
          {children}
        </TextComponent>
      </TextWithIcon>
    </button>
  );
};

export default Button;
