import type React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import LinkArrowRight from "@/assets/svg/menu/link-arrow-right.svg?react";
import { Button, P } from "@/base/components/Global";
import Icon from "@/base/components/Global/Icon";
import { Col, Row, VisibilityBlock } from "@/base/components/Layout";
import LogoutModal from "@/base/components/Modal/LogoutModal";
import { useUser } from "@/base/components/UserProvider";
import { isEnabledForGiftCardSoftLive } from "@/base/utils/featureFlagUtil";
import {
  CLOSE_SMALL_ICON,
  LOGIN_ICON,
  LOGOUT_ICON,
} from "@/constants/blobIcons";
import config from "@/constants/config";
import { NavigationPath } from "@/constants/navigation";
import { useAppSelector } from "@/store/hooks";
import { selectUserData } from "@/store/user";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ color, font, spacing }) => ({
  container: {
    borderBottom: `1px solid ${color.gray5}`,
  },
  btn: {
    background: "transparent",
    color: color.primaryBrand,
  },
  name: {
    fontWeight: font.weight.m,
    color: color.gray1,
  },
  type: {
    fontWeight: font.weight.m,
    color: color.gray3,
    fontSize: font.size.xxs,
  },
  apolloCard: {
    display: "flex",
    color: color.gray1,
    gap: spacing.m,
    fontSize: font.size.s,
    marginTop: spacing.x4l,
    cursor: "pointer",
  },
  link: {
    display: "flex",
    transition: "transform 0.15s ease-in-out",

    "$apolloCard:hover &": {
      transform: "translate(5px, 0)",
    },
  },
}));

type MenuHeaderProps = {
  setShouldShowCardModal: (value: boolean) => void;
  closeMenu: () => void;
};

const MenuHeader: React.FC<MenuHeaderProps> = ({
  closeMenu,
  setShouldShowCardModal,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userData = useAppSelector(selectUserData);
  const [{ logIn }] = useUser();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [shouldShowLogoutModal, setShouldShowLogoutModal] = useState(false);

  const navigateToProfile = (): void => {
    closeMenu();
    if (config.routerConfig.routerMode === "giftCard") {
      window.open(
        `${config.routerConfig.loyaltyProfileUrl}${NavigationPath.Profile}`,
        "_blank",
      );
    } else {
      navigate(NavigationPath.Profile);
    }
  };

  const navigateToMyApolloCard = (): void => {
    setShouldShowCardModal(true);
    closeMenu();
  };

  return (
    <Row noMargin className={classes.container}>
      {shouldShowLogoutModal && (
        <LogoutModal
          isOpen={shouldShowLogoutModal}
          onClose={() => setShouldShowLogoutModal(false)}
        />
      )}
      <Col>
        {!isAuthenticated && isEnabledForGiftCardSoftLive && (
          <Row noMargin>
            <Col col={6}>
              <Button
                prefixIcon={<Icon icon={LOGIN_ICON} />}
                action={logIn}
                className={classes.btn}
                transparentBlack
                gray1
              >
                {t("sign_in_button")}
              </Button>
            </Col>
          </Row>
        )}
        {isAuthenticated && userData.status === "succeeded" && (
          <Row noMargin>
            <Col col={12} noMarginBottom justifyBetween>
              <P
                bold
                gray1
                noMargin
              >{`${userData?.firstName} ${userData?.lastName}`}</P>

              <VisibilityBlock belowSm>
                <span onClick={closeMenu}>
                  <Icon icon={CLOSE_SMALL_ICON} height={36} width={36} />
                </span>
              </VisibilityBlock>
            </Col>
            <Col col={12}>
              <div
                className={classes.apolloCard}
                onClick={navigateToMyApolloCard}
              >
                {t("menu_apollo_club_id_card")}
                <Icon icon={<LinkArrowRight />} className={classes.link} />
              </div>
            </Col>
            <Row>
              <Col col={6}>
                <Button
                  action={navigateToProfile}
                  className={classes.btn}
                  transparentBlack
                  gray1
                >
                  {t("menu_personal_profile_data")}
                </Button>
              </Col>
              <Col col={6}>
                <Button
                  prefixIcon={<Icon icon={LOGOUT_ICON} />}
                  action={() => setShouldShowLogoutModal(true)}
                  className={classes.btn}
                  transparentBlack
                  gray1
                >
                  {t("btn_logout")}
                </Button>
              </Col>
            </Row>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default MenuHeader;
