import type React from "react";

import { Button, type ButtonProps, Icon } from "@/base/components/Global";
import { createUseStyles } from "@/theme";

const useStyles = createUseStyles(({ spacing, font, color }) => ({
  buttonTile: {
    backgroundColor: color.white,
    border: `1px solid ${color.gray4}`,
    flexDirection: "column",
    width: "100%",
    paddingLeft: spacing.m,
    paddingRight: spacing.m,
    fontSize: font.size.ms,
    lineHeight: font.lineHeight.s,

    height: 96,
  },
  children: {
    color: color.primaryBrand,
    fontSize: font.size.ms,
    fontWeight: font.weight.s,
    lineHeight: font.lineHeight.s,
    alignSelf: "end",
    textAlign: "center",
    wordBreak: "normal",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 140,
  },
}));

type ButtonTileProps = ButtonProps & {
  icon: React.ReactNode;
  children?: React.ReactNode;
};

const ButtonTile: React.FC<ButtonTileProps> = ({
  icon,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button className={classes.buttonTile} {...props}>
      <Icon icon={icon} />
      <div className={classes.children}>{children}</div>
    </Button>
  );
};

export default ButtonTile;
